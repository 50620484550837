import { getEnvImagePath, getEnvProductImagePath, getSignalRPath, getDocumentationPath, getEnvAssetImagePath, getEnvNetworkImagePath, getEnvFaviconPath } from '../utils/getConfig'

export const apiKey: any = "0EB408B9-C1D7-411F-81BF-3B4F38837BFC";
export const appImgSrcUrl: any = getEnvImagePath();
export const appProductImgSrcUrl: any = getEnvProductImagePath();
export const signalrURL: any = getSignalRPath();
export const documentationURL: any = getDocumentationPath();
export const appAssetImgSrcUrl: any = getEnvAssetImagePath();
export const appNetworkImgSrcUrl: any = getEnvNetworkImagePath();
export const appKeyProviderImgSrcUrl: any = getEnvAssetImagePath();
export const faviconSrcUrl: any = getEnvFaviconPath();
export const documentQuickStartURL: any = "https://vaultn.readme.io/";
export const documentApiOnboardingURL: any = "https://vaultn.readme.io/reference/get_api-v1-agreement-3";
export const envSandbox: any = "sandbox";
export const envTst: any = "tst";
export const mediakiwi: any = "/mediakiwi";
export const settings: any = "/settings";
export const productDetailNoImageURL: any = "/img/img-logo@2x.png";
export const productDetailImageURL: any = "https://vaultn.z01.azurefd.net/image/";
export const dashboardImageURL1: any = "https://vaultn.z01.azurefd.net/image/";
export const dashboardImageURL2: any = "/64/0/2";
export const defaultImageURL1: any = "https://vaultn.z01.azurefd.net/image/";
export const defaultImageURL2: any = "/64/0/2";
export const defaultImageURL3: any = "128/0/2";
export const numPerRequest: any = 100;
export const productDetailImageSpecs: any = "/512/0/2";
export const assetImageSpecs: any = "/64/0/2";
export const networkImageSpecs: any = "/64/0/2";
export const keyProviderImageSpecs: any = "/32/0/2";
export const assetExtractImageSpecs: any = "/150/0/2";
export const assetNoImageURL: any = "/img/img-prod-portrait.png";
export const networkNoImageURL: any = "/img/img-prod-portrait.png";
export const currentSiteId: any = 2;
export const defaultUserId: any = 2;
export const defaultVersion: any = 1;
export const connectedProduct: any = 1;
export const activeConnections: any = 1;
export const notConnectedProduct: any = 0;
export const originalUrl: any = "/mediakiwi";
export const pagerFirstPage: any = "1";
export const defaultPropTopNavigationUrl: any = "/mediakiwi";
export const defaultPropSideNavigationUrl: any = "/mediakiwi";
export const originalUrlAssetExtract: any = "/mediakiwi/products/extract_stock?openinframe=2";
export const originalUrlAssetInsert: any = "/mediakiwi/digital_assets/add_stock?openinframe=2";
export const originalUrlAgreementsAdd: any = "/mediakiwi/settings/administration/rights?item=0";
export const originalUrlProductsAdd: any = "/mediakiwi/products/products?item=0";
export const originalUrlAssetTransfer: any = "/mediakiwi/digital_assets/transfer_stock?openinframe=2";
export const originalUrlAssetInbox: any = "/mediakiwi/my_vault/stock_inbox";
export const originalUrlAssetOutbox: any = "/mediakiwi/my_vault/stock_outbox";
export const originalUrlAssetInboxModalPart1: any = "/2/portal?list=90&item=";
export const originalUrlAssetInboxModalPart2: any = "&openinframe=2";
export const originalUrlAssetOutboxModalPart1: any = "/mediakiwi/my_vault/stock_outbox?item=";
export const originalUrlAssetOutboxModalPart2: any = "&openinframe=2";
export const originalUrlRelationshipsAdd: any = "/mediakiwi/relationships/relationships?item=0";
export const originalUrlAssetPromised: any = "/mediakiwi/promised/promised?item=0";
export const originalUrlAssetMyAllocationsModalPart1: any = "/mediakiwi/my_vault/my_allocations?item=";
export const originalUrlAssetMyAllocationsModalPart2: any = "&openinframe=2";
export const originalUrlAssetAllocatedModalPart1: any = "/mediakiwi/my_vault/allocated?item=";
export const originalUrlAssetAllocatedModalPart2: any = "&openinframe=2";
export const originalUrlAgreementsDiscontinueModalPart: any = "/mediakiwi/my_vault/rights?item=";
export const originalUrlDeleterelationshipModalPart: any = "/mediakiwi/my_network/connections?item=-1&typeid=1&openinframe=2&guid=";
export const originalUrlInviteRelationshipModal: any = "/mediakiwi/my_network/connections?item=0&openinframe=2";
export const originalUrlPendingrelationshipModalPart: any = "/mediakiwi/my_network/connections?item=-1&typeid=2&openinframe=2&guid=";
export const originalUrlTransactionDetail: any = "/mediakiwi/digital_assets/transactions?item=";
export const originalUrlTransactionSummary: any = "/mediakiwi/transaction_details";
export const originalUrlTransactions: any = "/mediakiwi/my_vault/my_transactions";
export const originalUrlProductEdit: any = "/mediakiwi/my_vault/products?item=";
export const originalUrlAssetsSearch: any = "/mediakiwi/my_vault/assets";
//export const originalUrlInboxSearch: any = "/mediakiwi/my_vault/stock_inbox";
export const originalUrlInboxSearch: any = "/mediakiwi/my_vault/transfers";
export const originalUrlOutboxSearch: any = "/mediakiwi/my_vault/stock_outbox";
export const originalUrlAgreementsSearch: any = "/mediakiwi/settings/administration/rights";
export const originalUrlAdministrationTransactionsSearch: any = "/mediakiwi/settings/administration/transactions";
export const originalUrlProductsSearch: any = "/mediakiwi/products/products";
export const originalUrlTransactionsSearch: any = "/2/portal?list=91";
export const originalUrlTransactionSearch: any = "/2/portal?list=64";
export const originalUrlUsers: any = "/mediakiwi/settings/users";
export const originalUrlEditUsersModalPart1: any = "/mediakiwi/settings/users?item=";
export const originalUrlEditUsersModalPart2: any = "&openinframe=2";
export const originalUrlCertificate: any = "/2/portal?list=69";
export const originalUrlAddCertificate: any = "/mediakiwi/my_account/add_certificate?openinframe=2";
export const originalUrlWebhooks: any = "/2/portal?list=79";
export const originalUrlEditWebhookModalPart1: any = "/mediakiwi/settings/webhooks?item=";
export const originalUrlEditWebhookModalPart2: any = "&openinframe=2";
export const originalUrlUploadImage: any = "/mediakiwi?list=7&openinframe=2&referid=_ImageID&gallery=1&item=0&isimage=0";
export const originalUrlAddUsersModalPart1: any = "/2/portal?list=49&item=";
export const originalUrlAddUsersModalPart2: any = "&openinframe=2";
export const originalUrlBlacklistings: any = "/mediakiwi/my_vault/blacklistings";
export const originalUrlApiInsights: any = "/mediakiwi/settings/api_insights";
export const originalUrlDocumentationAdd: any = "/mediakiwi/settings/documentation?item=0";
export const originalUrlDocumentationEdit: any = "/mediakiwi/settings/documentation?item=";
export const originalUrlAccountAuthenticator: any = "/mediakiwi/my_account/connect_authenticator?openinframe=2";
export const originalUrlTransactionOwnerDetail1: any = "/mediakiwi/digital_assets/transaction_details?owner=";
export const originalUrlTransactionOwnerDetail2: any = "&transaction=";
export const originalUrlStockExtraction: any = "/mediakiwi/my_account/stock_extraction_methods?openinframe=2";
export const originalUrlAssetInboxModal: any = "/mediakiwi/rights/accept_offer?openinframe=2&item=";
export const originalUrlAddUsersModal: any = "/mediakiwi/my_account/add_user_popup?openinframe=2";
export const originalUrlReportDuplicateModalPart1: any = "/mediakiwi/settings/administration/report_duplicate_product?item=";
export const originalUrlReportDuplicateModalPart2: any = "&openinframe=2";
export const originalUrlFinanceTransactionsSearch: any = "/mediakiwi/finance/transactions";
export const originalUrlMarketsSearch: any = "/mediakiwi/settings/markets";
export const originalUrlAddWebhookModal: any = "/mediakiwi/settings/webhooks?item=0&openinframe=2";
export const originalUrlInvitedrelationshipModalPart1: any = "/mediakiwi/my_network/connections?item=-1&typeid=3&guid=";
export const originalUrlInvitedrelationshipModalPart2: any = "&openinframe=2";
export const originalUrlMarketsModalPart1: any = "/mediakiwi/settings/administration/market_settings?region=1&item=0&country=";
export const originalUrlMarketsModalPart2: any = "&openinframe=2";
export const originalUrlPriceListsSearch: any = "/mediakiwi/finance/price_lists";
export const originalUrlPriceListsAdd: any = "/mediakiwi/finance/price_lists?item=0";
export const originalUrlPriceListsEdit: any = "/mediakiwi/finance/price_lists?item=";
export const originalUrlRightsEdit: any = "/mediakiwi/settings/administration/rights?item=";
export const originalUrlJITEndpoints: any = "/mediakiwi/settings/jit_endpoints";
export const originalUrlPromotionsEdit: any = "/mediakiwi/finance/promotions?item=";
export const originalUrlPromotionsAdd: any = "/mediakiwi/finance/promotions?item=0";
export const originalUrlFinancePromotionModalPart1: any = "/mediakiwi/finance/promotion_items?item=";
export const originalUrlFinancePromotionModalPart2: any = "&openinframe=2";
export const originalUrlProductEditionsAdd: any = "/mediakiwi/settings/administration/product_editions?item=0";
export const originalUrlProductEditionsEdit: any = "/mediakiwi/settings/administration/product_editions?item=";
export const originalUrlProductFranchisesAdd: any = "/mediakiwi/settings/administration/product_franchises?item=0";
export const originalUrlProductFranchisesEdit: any = "/mediakiwi/settings/administration/product_franchises?item=";
export const originalUrlVaultnManagement: any = "/mediakiwi/settings/administration/vaultn_management";
export const originalUrlVaultnManagementDetail: any = "/mediakiwi/settings/administration/vaultn_management?item=0";
export const originalUrlRegionsAdd: any = "/mediakiwi/settings/administration/regions?item=0";
export const originalUrlRegionsEdit: any = "/mediakiwi/settings/administration/regions?item=";
export const originalUrlExchangeRatesSearch: any = "/mediakiwi/settings/administration/exchange_rates";
export const originalUrlGeneralSettings: any = "/mediakiwi/settings/administration/general_settings";
export const originalUrlVaultnInvitations: any = "/mediakiwi/settings/administration/vaultn_invitations";
export const originalUrlVaultnInvitationsDetail: any = "/mediakiwi/settings/administration/vaultn_invitations?item=0";
export const originalUrlVaultnInviteCompany:any = "/mediakiwi/settings/administration/invite_new_company?openinframe=2";
export const originalUrlProductTypes: any = "/mediakiwi/settings/administration/product_types";
export const originalUrlAddProductTypes: any = "/mediakiwi/settings/administration/product_types?item=0&openinframe=2";
export const originalUrlEditProductTypesModalPart1: any = "/mediakiwi/settings/administration/product_types?item=";
export const originalUrlEditProductTypesModalPart2: any = "&openinframe=2";
export const originalUrlKeyProvidersAdd: any = "/mediakiwi/settings/administration/key_providers?item=0";
export const originalUrlPageContentEdit: any = "/mediakiwi/settings/administration/page_content?item=0";
export const originalUrlCountries: any = "/mediakiwi/settings/administration/countries";
export const originalUrlKeyProvidersEdit: any = "/mediakiwi/settings/administration/key_providers?item=";
export const originalUrlMailTemplatesSearch: any = "/mediakiwi/settings/administration/mail_templates";
export const originalUrlMailTemplatesAdd: any = "/mediakiwi/settings/administration/mail_templates?item=0";
export const originalUrlMailTemplatesEdit: any = "/mediakiwi/settings/administration/mail_templates?item=";
export const originalUrlSendEmailTestModalPart1: any = "/mediakiwi/send_test_mail?item=";
export const originalUrlSendEmailTestModalPart2: any = "&importtype=open&openinframe=1";
export const originalUrlAddCountries: any = "/mediakiwi/settings/administration/countries?item=1&openinframe=2";
export const originalUrlEditCountriesModalPart1: any = "/mediakiwi/settings/administration/countries?item=1&iso=";
export const originalUrlEditCountriesModalPart2: any = "&openinframe=2";
export const originalUrlConnectionsSearch: any = "/mediakiwi/my_network/connections";
export const utcString: any = " UTC";