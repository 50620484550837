<style lang="scss" src="./FilterChip.scss"></style>
<script lang="ts" src="./FilterChip.ts"></script>

<template>
  <div
    class="user-select__none display-inline"
    @click="item.value !== '-1' ? null : $emit('onCancelFilter')"
  >
    <q-chip
      color="white margin-bottom-15"
      :class="item.value !== '-1' ? '' : 'cursor-pointer'"
      class="q-px-lg border-1-color-B3B3B3"
    >
      {{ item.title }}
      <font-awesome-icon
        @click="$emit('onCancelFilter')"
        class="q-pl-md cursor-pointer red"
        :icon="item.value !== '-1' ? 'fal fa-close' : 'fal fa-trash'"
        size="sm"
      />
    </q-chip>
  </div>
</template>
