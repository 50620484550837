import { toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_q_chip = _resolveComponent("q-chip")!

  return (_openBlock(), _createElementBlock("div", {
    class: "user-select__none display-inline",
    onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.item.value !== '-1' ? null : _ctx.$emit('onCancelFilter')))
  }, [
    _createVNode(_component_q_chip, {
      color: "white margin-bottom-15",
      class: _normalizeClass([_ctx.item.value !== '-1' ? '' : 'cursor-pointer', "q-px-lg border-1-color-B3B3B3"])
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.item.title) + " ", 1),
        _createVNode(_component_font_awesome_icon, {
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('onCancelFilter'))),
          class: "q-pl-md cursor-pointer red",
          icon: _ctx.item.value !== '-1' ? 'fal fa-close' : 'fal fa-trash',
          size: "sm"
        }, null, 8, ["icon"])
      ]),
      _: 1
    }, 8, ["class"])
  ]))
}