import { createStore } from "vuex";
import router from "@/router";
import createPersistedState from "vuex-persistedstate";
import { ProfileModel } from "@/models/profile";
import { useCookies } from "vue3-cookies";
import { currentSiteId, originalUrl } from "@/extensions/extra";
import { GET_PROFILE_IMAGE_URL } from "@/services/header-profile-image-service"
import { GET_PROFILE_INFO } from "@/services/header-profile-info-service"
import { returnErrors } from "@/utils/returnErrors";
import useQuasar from 'quasar/src/composables/use-quasar.js';;
import { GET_OWNER_IMAGE } from "@/services/image-upload-service"


const loggedInKey = "vue_user_loggedIn";
const authenticatedKey = "vue_user_authenticated";
const { cookies } = useCookies();
const $q = useQuasar();

export default createStore({

  plugins: [createPersistedState()],

  state: {
    isLoggedIn: false,
    loadingSpinner: false,
    profileData: new ProfileModel(),
    drawer: {
      open: true,
    },
    profileImage: "/img/img-profile.png",
    roleGuid: "",
    roleName: "",
    userGuid: "",
    ownerGuid: "",
    ownerName: "",
    ownerRole: "",
    userId: "",
    ownerId: "",
    isAuthenticated: false,
    isReportReady: false,
  },
  mutations: {
    logIn(state) {
      state.isLoggedIn = true;
      sessionStorage.setItem(loggedInKey, state.isLoggedIn.toString());
      router.push("/2-fa-authenticate/");
    },
    logOut(state) {
      state.isLoggedIn = false;
      state.isAuthenticated = false;
      state.profileData = new ProfileModel();
      state.drawer.open = true;
      state.profileImage = "/img/img-profile.png";
      sessionStorage.setItem(loggedInKey, state.isLoggedIn.toString());
      sessionStorage.setItem(authenticatedKey, state.isAuthenticated.toString());
      localStorage.clear();
      sessionStorage.clear();
      cookies.remove("MKAPI");
      router.push("/");
    },
    setProfileData(state, data: ProfileModel) {
      if (data) {
        state.profileData.userEmail = data.userEmail;
        state.profileData.userName = data.userName;
      }
    },
    async setProfileInfo(state) {
      const [error, response] = await GET_PROFILE_INFO(originalUrl)
      if (error) {
        const errorMessage: any = returnErrors(error);
        $q.notify({
          group: false,
          color: 'negative',
          message: errorMessage,
          position: 'top',
          icon: 'report_problem'
        });
      } else {
        state.roleGuid = response.roleGuid;
        state.roleName = response.roleName;
        state.userGuid = response.userGuid;
        state.ownerGuid = response.ownerGuid;
        state.ownerName = response.ownerName;
        state.ownerRole = response.ownerRole;
        state.userId = response.userId;
        state.ownerId = response.ownerId;
      }
    },
    async setProfileImage(state) {
      const [error, response] = await GET_OWNER_IMAGE(state.userId)
      if (error) {
        const errorMessage: any = returnErrors(error);
        $q.notify({
          group: false,
          color: 'negative',
          message: errorMessage,
          position: 'top',
          icon: 'report_problem'
        });
        state.profileImage = "/img/img-profile.png";
      } else {
        if (response) {
          state.profileImage = response;
        } else {
          state.profileImage = "/img/img-profile.png";
        }
      }
    },
    toggleDrawer(state) {
      state.drawer.open = !state.drawer.open;
    },
    authenticate(state) {
      state.isAuthenticated = true;
      sessionStorage.setItem(authenticatedKey, state.isAuthenticated.toString());
      router.push("/home/dashboard/");
    },
    setIsReportReady(state, val) {
      state.isReportReady = val;
    }
  },
  actions: {
    logIn(context) {
      context.commit("logIn");
    },
    logOut(context) {
      context.commit("logOut");
    },
    setProfileData(context, data) {
      context.commit("setProfileData", data);
    },
    toggleDrawer(context) {
      context.commit("toggleDrawer");
    },
    setProfileImage(context) {
      context.commit("setProfileImage");
    },
    setProfileInfo(context) {
      context.commit("setProfileInfo");
    },
    authenticate(context) {
      context.commit("authenticate");
    },
    setIsReportReady(context) {
      context.commit("setIsReportReady");
    },
  },
  getters: {},
  modules: {},
});