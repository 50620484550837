import service from "@/plugins/axios"
import { ContentModel } from "@/models/content";
import { ButtonModel } from "@/models/button";

const request = new service()
const source = '/mkapi/';

export const GET_CONTENT = (contentModel: ContentModel) => {
    return request.axiosCallWithCredentials({
        method: `get`, url: `${source}` + "content/GetContent",
        headers: {
            "original-url": contentModel.originalUrl
        },
        params: {
            "currentSiteId": contentModel.currentSiteId,
        }
    })
}

export const GET_CONTENT_WITH_PAGER = (contentModel: ContentModel, pageNum: any) => {
    contentModel.originalUrl += `?set=${pageNum}`
    return request.axiosCallWithCredentials({
        method: `get`, url: `${source}` + "content/GetContent",
        headers: {
            "original-url": contentModel.originalUrl
        },
        params: {
            "currentSiteId": contentModel.currentSiteId,
        }
    })
}

export const GET_CONTENT_WITH_PAGER_SELF = (contentModel: ContentModel, pageNum: any) => {
    contentModel.originalUrl += `&set=${pageNum}`
    return request.axiosCallWithCredentials({
        method: `get`, url: `${source}` + "content/GetContent",
        headers: {
            "original-url": contentModel.originalUrl
        },
        params: {
            "currentSiteId": contentModel.currentSiteId,
        }
    })
}

export const POST_CONTENT = (contentModel: ContentModel) => {
    return request.axiosCallWithCredentials({
        method: `post`, url: `${source}` + "content/PostContent", data: contentModel,
        headers: {
            "original-url": contentModel.originalUrl
        },
        params: {
            "currentSiteId": contentModel.currentSiteId
        }
    })
}

export const POST_CONTENT_AUTOPOSTBACK = (buttonModel: ButtonModel) => {
    return request.axiosCallWithCredentials({
        method: `post`, url: `${source}` + "content/PostContent", data: buttonModel,
        headers: {
            "original-url": buttonModel.originalUrl
        },
        params: {
            "currentSiteId": buttonModel.currentSiteId
        }
    })
}
