import store from "@/store";
import i18n from "@/i18n";
import useQuasar from 'quasar/src/composables/use-quasar.js';;
import { useCookies } from "vue3-cookies";

export function checkCookie() {
  const $q = useQuasar();
  const { cookies } = useCookies();
  const cookieValue = cookies.get("MKAPI");
  if (cookieValue == null) {
    $q.notify({
      color: "negative",
      group: false,
      message: i18n.global.t("tokenExpired.definition").toString(),
      position: "top",
      icon: "report_problem",
    });
    store.dispatch("logOut");
  }
}
