import { defineComponent, onMounted, ref } from "vue";
import myVault from "@/json/my_vault.json";
import settings from "@/json/settings.json";

const menu = ref();

export default defineComponent({
  name: "TopMenu",
  props: {
    folder: {
      type: String,
      required: true,
    },
    highlightedIndex: {
      type: Number,
      required: true,
    },
  },

  setup(props) {
    if (props.folder == "my_vault") {
      menu.value = myVault;
    } else if (props.folder == "settings") {
      menu.value = settings;
    }

    onMounted(() => {});

    return {
      menu,
      props,
    };
  },
});
