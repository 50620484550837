import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios'
import { appImgSrcUrl } from "@/extensions/extra";
import store from "@/store";

export default class ApiService {
    private axiosInstanceWithCredentials: AxiosInstance;

    private axiosInstanceWithoutCredentials: AxiosInstance;

    constructor() {

        this.axiosInstanceWithCredentials = axios.create({
            baseURL: process.env.VUE_APP_API_URL,
            withCredentials: true,
            headers: { 'Content-Security-Policy': 'img-src ' + appImgSrcUrl }
        });

        this.axiosInstanceWithoutCredentials = axios.create({
            baseURL: process.env.VUE_APP_API_URL,
            withCredentials: false,
            headers: { 'Content-Security-Policy': 'img-src ' + appImgSrcUrl }
        })
    }

    async axiosCallWithCredentials<T>(config: AxiosRequestConfig) {
        try {
            store.state.loadingSpinner = true;
            const { data }: AxiosResponse = await this.axiosInstanceWithCredentials.request<T>(config)
            store.state.loadingSpinner = false;
            return [null, data]
        } catch (error) {
            store.state.loadingSpinner = false;
            return [error, null]
        }
    }

    async axiosCallWithoutCredentials<T>(config: AxiosRequestConfig) {
        try {
            store.state.loadingSpinner = true;
            const { data }: AxiosResponse = await this.axiosInstanceWithoutCredentials.request<T>(config)
            store.state.loadingSpinner = false;
            return [null, data]
        } catch (error) {
            store.state.loadingSpinner = false;
            return [error, null]
        }
    }
}

export const service = new ApiService()