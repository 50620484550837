import { computed, defineComponent, onMounted, onUpdated, reactive, ref, watchEffect } from "vue";
import store from "@/store";
import { checkCookie } from "@/utils/checkCookie";
import { returnErrors } from "@/utils/returnErrors";
import { LOG_OUT } from "@/services/login-service"
import useQuasar from 'quasar/src/composables/use-quasar.js';;

export default defineComponent({
  name: "HeaderComponent",

  setup() {
    const brandImage = ref("/img/DeCS-Color@5x-1.png");
    const userEmail = ref();
    const userName = ref();
    const profileDialog = ref(false);
    const profileImage = computed(
      () => store.state.profileImage,
    );
    const $q = useQuasar();
    watchEffect(() => {
      userEmail.value = store.state.profileData.userEmail
      userName.value = store.state.profileData.userName;
    });

    onMounted(() => {
      checkCookie();
      store.dispatch("setProfileInfo");
      setTimeout(function () {
        store.dispatch("setProfileImage");
      }, 500);
    })

    function dialogToggle() {
      profileDialog.value = !profileDialog.value;
    }

    async function logOut() {
      const [error, response] = await LOG_OUT()
      if (error) {
        const errorMessage: any = returnErrors(error);
        $q.notify({
          group: false,
          color: 'negative',
          message: errorMessage,
          position: 'top',
          icon: 'report_problem'
        });
      } else {
        dialogToggle();
        store.dispatch("logOut");
      }
    }

    return {
      brandImage,
      userEmail,
      userName,
      profileDialog,
      profileImage,
      dialogToggle,
      logOut,
    }
  },
})
