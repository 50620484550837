import service from "@/plugins/axios"

const request = new service()
const source = '/api/';

export const UPLOAD_IMAGE = (data: FormData, contextType: any, id: any) => {
    return request.axiosCallWithoutCredentials({
        method: `post`, url: `${source}` + "content/uploadimage", data: data,
        params: {
            "contextType": contextType,
            "id": id,
        },
    })
}

export const GET_OWNER_IMAGE = (ownerId: any) => {
    return request.axiosCallWithoutCredentials({
        method: `get`, url: `${source}` + "content/ownerImage/" + `${ownerId}`
    })
}