import service from "@/plugins/axios"

const request = new service()
const source = '/api/';

export const GET_PROFILE_INFO = (originalUrl: any) => {
    return request.axiosCallWithCredentials({
        method: `get`, url: `${source}` + "user/info",
        headers: {
            "original-url": originalUrl
        },
    })
}
