import service from "@/plugins/axios"
import { TopNavigationModel } from "@/models/topNavigation";

const request = new service()
const source = '/mkapi/';

export const GET_TOP_NAVIGATION = (topNavigationModel: TopNavigationModel, groupType: any) => {
    if (groupType == null) {
        return request.axiosCallWithCredentials({
            method: `get`, url: `${source}` + "navigation/GetTopnavigation",
            headers: {
                "original-url": topNavigationModel.originalUrl
            },
            params: {
                "currentSiteId": topNavigationModel.currentSiteId
            }
        })
    } else {
        return request.axiosCallWithCredentials({
            method: `get`, url: `${source}` + "navigation/GetTopnavigation",
            headers: {
                "original-url": topNavigationModel.originalUrl
            },
            params: {
                "currentSiteId": topNavigationModel.currentSiteId,
                "groupTag": groupType
            }
        })
    }

}
