import service from "@/plugins/axios"
import { FilterModel } from "@/models/filter";

const request = new service()
const source = '/mkapi/';

export const FILTER = (filterModel: FilterModel) => {
    return request.axiosCallWithCredentials({
        method: `post`, url: `${source}` + "content/PostContent", data: filterModel,
        headers: {
            "original-url": filterModel.originalUrl,
            "currentSiteId": filterModel.currentSiteId
        },
    })
}
