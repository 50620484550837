import service from "@/plugins/axios"
import { LoginModel } from '@/models/login'
import { ResetPasswordModel } from '@/models/resetPassword'
import { ResetPasswordApiModel } from "@/models/resetPasswordApi";

const request = new service()
const source = '/mkapi/';
const sourceApi = '/api/';

export const LOGIN = (loginModel: LoginModel) => {
    return request.axiosCallWithCredentials({
        method: `post`, url: `${source}` + "authentication/Login", data: loginModel
    })
}

export const RESET_PASSWORD = (resetPasswordModel: ResetPasswordModel) => {
    return request.axiosCallWithCredentials({
        method: `post`, url: `${source}` + "authentication/ResetPassword", data: resetPasswordModel
    })
}

export const RESET_PASSWORD_USER = (resetPasswordModelApi: ResetPasswordApiModel) => {
    return request.axiosCallWithCredentials({
        method: `post`, url: `${sourceApi}` + "user/sendpasswordreset", data: resetPasswordModelApi
    })
}

export const LOG_OUT = () => {
    return request.axiosCallWithCredentials({
        method: `post`, url: `${source}` + "authentication/Logout"
    })
}
