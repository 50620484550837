import { defineComponent, ref, Ref } from "vue";

export default defineComponent({
  name: "FilterChip",
  props: ["itemData"],
  emits: ["onCancelFilter"],

  setup(props) {
    const item: Ref<any> = ref(props.itemData)
    return { item }
  },
})